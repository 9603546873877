import React from 'react';
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from '../../services/api/loginService';

//import ErrorMessage from '../../pages/shared/ErrorMessage';
//import { userLogin, resetInvalidToken } from '../../store/modules/loginSlice';
//import AuthService from '../../services/utility/authenticationService';
//import { alertMessages } from '../../constants/app-constant';

function AppLogin() {

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "all",
    });

    
    let isLoading = false;

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    //const isValidAuthToken = useSelector(
    //    (state) => state.loginReducer.authToken
    //);

    //const isInvalidToken = useSelector(
    //    (state) => state.loginReducer.isInvalidToken
    //);

    //const isLoading = useSelector(
    //    (state) => state.loginReducer.isLoading
    //);

    //if (Object.keys(isValidAuthToken).length === 0) {
    //    //navigate('/login')
    //} else {
    //    AuthService.updateTheTokenToStorage(isValidAuthToken);
    //    navigate('/')
    //}

    const onSubmit = async(formvalues) => {
        try {
            isLoading=true;
            const obj = { UserName: userName, Password: password };
            const response = await login(obj);
            var data = decodeJWT(response.data);
            debugger;
            console.log(data);
            localStorage.setItem('loginToken', response.data);
            localStorage.setItem('role', data.role);
            if (data.role == "Admin") {
                window.location.href = "#/SalesTarget";
            }
            else {
                window.location.href = "#/EmployeeSalesTarget/" + null + "/" + parseInt(data.UserId);
;
            }
            console.log(decodeJWT(response.data));
            isLoading=false;
        } catch (err) {
            isLoading=false;
        }
    };

    const decodeJWT = (token) => {
        const base64Url = token.split('.')[1]; // Get the payload part of the token
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Replace URL-safe characters
        const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join('')); // Decode base64 and convert to JSON

        return JSON.parse(jsonPayload); // Parse JSON and return the decoded payload
    };

    const onError = (formvalues) => {
      //  console.log(formvalues)
    };

   
    return (
        <>
            <div className="d-flex justify-content-center align-items-center login_section" style={{ height: '100vh' }}>
                <div className="card aon-card sf-contact-info-box" style={{ flex: '0 1 500px' }}>
                    <div className="card-header aon-card-header">
                        <h4><i className="fa fa-user"></i> login</h4>
                    </div>
                    <div className="card-body aon-card-body">
                        <form className="aon-login-form" onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className="row">

                                <div className="col-md-12">
                                    <div className={`form-group ${errors.userName ? 'error_field' : ''}`}>
                                        <div className="aon-inputicon-box">
                                            <input className="form-control sf-form-control" required
                                                id="userName" name="userName" type="text" placeholder="User Name"
                                                onBlur={(e) => setUserName(e.target.value)}
                                            />
                                            <i className="aon-input-icon fa fa-user"></i>
                                        </div>
                                        {/*{*/}
                                        {/*    errors.userName &&*/}
                                        {/*    <ErrorMessage errorType={errors.userName.type} errorFieldName={'User Name'} />*/}
                                        {/*}*/}
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className={`form-group ${errors.password ? 'error_field' : ''}`}>
                                        <div className="aon-inputicon-box">
                                            <input className="form-control sf-form-control" id="password" required
                                                name="password" type="password" placeholder="Password"
                                                onBlur={(e) => setPassword(e.target.value)}
                                            />
                                            <i className="aon-input-icon fa fa-lock"></i>
                                        </div>
                                        {/*{*/}
                                        {/*    errors.password &&*/}
                                        {/*    <ErrorMessage errorType={errors.password.type} errorFieldName={'Password'} />*/}
                                        {/*}*/}
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group d-flex aon-login-option justify-content-between">
                                        <div className="aon-login-opleft">
                                        </div>
                                        <div className="aon-login-opright">
                                            <a href="/#/forgotpassword">Forgot Password</a>
                                        </div>
                                    </div>
                                </div>

                                {/*{*/}
                                {/*    isInvalidToken && (*/}
                                {/*        <div className="col-md-12" >*/}
                                {/*            <div className="bg-danger text-danger p-2 mb-4 rounded">*/}
                                {/*                {alertMessages.loginFailure}*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    )*/}
                                {/*}*/}

                                <div className="col-md-12">
                                    <button type="submit" className="site-button w-100">{isLoading ? '' : 'Login'}
                                        <i className={isLoading ? 'fa fa-spinner fa-spin fa-2x text-white' : 'feather-arrow-right'}></i></button>
                                </div>

                            </div>
                        </form>
                    </div>

                </div>
            </div>

        </>
    );
}

export default AppLogin;