import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import AppHeader from '../../Components/layout/AppHeader';
import AppSidebar from '../../Components/layout/AppSidebar';
import AppLogin from '../../Components/login/AppLogin';
import { useNavigate } from 'react-router-dom';

function AppLayoutContainer() {

  const navigate = useNavigate()
  const [isLoginExist, setIsLoginExist] = useState(true);
    const [role, setRole] = useState(localStorage.getItem('role'));

    useEffect(() => {
        const authTokenDetail = localStorage.getItem('loginToken');
   if (authTokenDetail) {
      // Update the state using setState
      setIsLoginExist(true);
    }
    else {
      navigate('/login')
    }
  }, []);


  return (
    <>
      <div className="page-wraper">
        <AppHeader isLoginExist={isLoginExist} />
        {isLoginExist ? (
          <>
            {role === 'Admin' ? <AppSidebar /> : null}
            <div id="content">
              <div className="content-admin-main">
                <Outlet />
              </div>
            </div>
          </>
        ) : (
          <AppLogin />
        )}
      </div>
    </>
  );
}

export default AppLayoutContainer;