import React, { useEffect, useState } from 'react';
import { getSalesTarget, getFinancialYear } from '../../services/api/salesTargetService';
import { getEmployeeOutstandingDetails } from '../../services/api/outstandingService';
import { v4 as uuidv4 } from 'uuid';
import Chart from "react-apexcharts";
import Modal from 'react-bootstrap/Modal';

function SalesTarget() {

    const [financialYear, setFinancialYear] = useState(0);
    const [financialYearList, setFinancialYearList] = useState([]);
    const [empList, setEmpList] = useState([]);
    const [osList, setosList] = useState([]);
    const [loading, isLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ChartData, setChartData] = useState([]);
    const [ChartOptions, setChartOptions] = useState({});

    const getFinancialYearList = async() => {
        try {
            const response = await getFinancialYear();
            setFinancialYearList(response.data);
        } catch (err) {

        }
    };

    useEffect(() => {
        (async () => {
            getFinancialYearList();
        })();
    }, []);


    const getEmployeesTarget = async () => {
        console.log(financialYear);
        var obj = { financialYearId: financialYear, grouping:'monthly',empIds:null }
        if (financialYear != 0)
            try {
                console.log(financialYear);
                const response = await getSalesTarget(obj);
                console.log(response);
                setEmpList(response.data.summary);

                const categories = response.data.summary.map(target => target.EmpName);
               
                const sparesSalesAcheived = response.data.summary.map(target => target.SparesAchieved?.toFixed(3));
                const targetData = response.data.summary.map(target => target.SpareTarget?.toFixed(3));
                const serviceSalesAcheived = response.data.summary.map(target => target.ServicesAchieved?.toFixed(3));
                const servicetargetData = response.data.summary.map(target => target.ServiceTarget?.toFixed(3));
                const amcSalesAcheived = response.data.summary.map(target => target.AmcAchieved?.toFixed(3));               
                const totalOutstandingAmt = response.data.summary.map(target => target.TotalOutstandingAmt);
                const totalTarget = response.data.summary.map(target => target.TotalTarget?.toFixed(2));
                const totalTargetAcheived = response.data.summary.map(target => target.TotalTargetAcheived?.toFixed(2));
                console.log(amcSalesAcheived);
                const chartData = {
                    series: [
                        {
                            name: 'Spares Achieved',
                            type:'column',
                            data: sparesSalesAcheived
                        },                        
                        {
                            name: 'Service Achieved',
                            type: 'column',
                            data: serviceSalesAcheived
                        },                       
                        {
                            name: 'Amc Achieved',
                            type: 'column',
                            data: amcSalesAcheived
                        },
                        {
                            name: 'Total Outstanding',
                            type: 'column',
                            fillColor: 'red',
                            data: totalOutstandingAmt
                        },
                        
                        {
                            name: 'Total Target',
                            type: 'line',
                            data: totalTarget
                        },
                        {
                            name: 'Total Target Acheived',
                            type: 'line',
                            data: totalTargetAcheived
                        }
                    ],
                    options: {
                        chart: {
                            id: 'sales-comparison-chart',
                            toolbar: {
                                show: false
                            }
                        },
                        xaxis: {
                            categories: categories,
                        },
                        yaxis: {
                            title: {
                                text: 'Sales'
                            }
                        },
                        markers: {
                            size: 5
                        },
                        legend: {
                            position: 'top'
                        }
                    }
                };

                setChartData(chartData);
                ////const chartData = [
                ////    {
                ////        name: 'Bar Dataset',
                ////        type: 'bar',
                ////        data: empList.map(obj => obj.SalesAchieved),
                ////    },
                ////    {
                ////        name: 'Line Dataset',
                ////        type: 'line',
                ////        data: empList.map(obj => obj.Target),
                ////    },
                ////];

                // Options for the chart
                //const chartOptions = {
                //    chart: {
                //        id: 'mixed-chart',
                //    },
                //    xaxis: {
                //        categories: empList.map(obj => obj.EmpName),
                //    },
                //    colors: ['#008FFB', '#00E396'],
                //};

                //setChartData(chartData);
                //setChartOptions(options);
            } catch (err) {

            }
    }

    const handleClick = (empId) => {
        window.location.href = "#/EmployeeSalesTarget/" + financialYear + "/" + empId;
    }

    const openModalWithId = async(id) => {       
        setIsModalOpen(true);
        try {
            console.log(financialYear);
            const response = await getEmployeeOutstandingDetails(id);
            setosList(response.data);
        } catch (err) {

        }
    };

    const closeModal = () => {
        setIsModalOpen(false);       
    };

    return (
        <>
            {/* My Booking Section */}
            <div className="card aon-card">
                <div className="card-header aon-card-header">
                    <h4>Sales Target</h4>
                </div>
                <div className="card-body aon-card-body">

                    <div className="sf-availability-times-tab m-b20">
                        <div className="sf-custom-tabs sf-custom-new">
                            <div className="sf-tabs-content">

                                {/* Filter Section */}
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label>Select Financial Year</label>
                                            <div className="aon-inputicon-box  datepicker-with-icon">
                                                <select className="form-control" name="Year"
                                                    onChange={(e) => setFinancialYear(e.target.value)}>
                                                    <option value="">Select a Financial Year</option>
                                                    {financialYearList && financialYearList.length > 0 && financialYearList?.map((item, key) => (
                                                        <option key={key} value={item.Id}>{item.Name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div className="col-lg-2 jus">
                                      {/*  <Link to={`/EmployeeSalesTarget/${financialYear}/${empId}`}>*/}
                                        <button type="button"
                                            className="admin-button m-t-38 assignButton btn margin-r-10"
                                            onClick={() => getEmployeesTarget()}>Get  Target</button>
                                       {/* </Link>*/}
                                    </div>
                                </div>

                                {/* Loader */}
                                {
                                    loading && (
                                        <div className="skeleton p-0">
                                            {[...Array(5)].map(() => (
                                                <div key={uuidv4()} className="line mb-1" style={{ height: '67px', width: '100%' }}></div>
                                            ))}
                                        </div>
                                    )
                                }
                                {
                                    empList?.length > 0 && <Chart options={ChartData.options} series={ChartData.series} type="line" height={400} />
                                }

                                {/* MyBooking table section */}
                                {
                                    !loading && (<div className="sf-bs-data-table">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-bordered example-dt-table aon-booking-table" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Employee No</th>
                                                        <th >Employee Name</th>
                                                        <th >Department</th>
                                                        {/* <th>Yearly Target</th>*/}
                                                        <th>Total Target </th>
                                                        <th>Total Target Acheived</th>
                                                        <th>Difference</th>
                                                        <th>Spares Target Acheived / Spares Target</th>                                                        
                                                        <th>Service Target Acheived / Service Target</th>                                                        
                                                        <th>Amc Target Acheived / Amc Target</th>
                                                        <th>0-30 Days OS</th>
                                                        <th>30-60 Days OS</th>
                                                        <th>Above 60 Days OS</th>
                                                        <th>Total OS Amt</th>
                                                        {/*<th>Difference</th>*/}
                                                        <th  colspan="2">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        empList.length > 0 ? (
                                                            empList?.map(emp => (
                                                                <tr key={uuidv4()}>
                                                                    <td className="align_middle">{emp.EmpNo}</td>
                                                                    <td  className="align_middle">{emp.EmpName}</td>
                                                                    <td  className="align_middle">{emp.Department}</td>
                                                                    <td  className="align_middle">{emp.TotalTarget?.toFixed(2)}</td>
                                                                    <td  className="align_middle">{emp.TotalTargetAcheived?.toFixed(2)}</td>
                                                                    <td className="align_middle" style={{ color: emp.TotalTargetAcheived?.toFixed(2) - emp.TotalTarget?.toFixed(2) > 0 ? "green" : "red" }}>{emp.TotalTargetAcheived?.toFixed(2) - emp.TotalTarget?.toFixed(2)}</td>
                                                                    <td className="align_middle">{emp.SparesAchieved?.toFixed(2)} <span style={{ fontWeight: 'bold' }}> / </span>{emp.SpareTarget?.toFixed(2)}</td>
                                                                    <td className="align_middle">{emp.ServicesAchieved?.toFixed(2)} <span style={{ fontWeight: 'bold' }}> / </span>{emp.ServiceTarget?.toFixed(2)}</td>
                                                                    <td className="align_middle">{emp.AmcAchieved?.toFixed(2)} <span style={{ fontWeight: 'bold' }}> / </span>{emp.AmcTarget?.toFixed(2)}</td>                                                                    
                                                                    <td className="align_middle">{emp.OutstandingOneMonth}</td>
                                                                    <td className="align_middle">{emp.Outstanding2Months}</td>
                                                                    <td className="align_middle">{emp.OutstandingAbove3Months}</td>
                                                                    <td className="align_middle">{emp.TotalOutstandingAmt}</td>
                                                                    {/*<td className="align_middle">*/}
                                                                    {/*    {Math.abs(emp.Difference.toFixed(2))}*/}
                                                                    {/*</td>*/}
                                                                    <td  className="align_middle">
                                                                       {/* <Link to={`/EmployeeSalesTarget/${financialYear}/${emp.empId}`}>*/}
                                                                        <button type="button"
                                                                            className="btn btn-info btn-sm margin-r-10"
                                                                            onClick={()=>handleClick(emp.EmpId)}
                                                                        > View</button>
                                                                       
                                                                            {/*</Link>*/}
                                                                    </td>
                                                                    <td className="align_middle">
                                                                        <button type="button"
                                                                            className="btn btn-info btn-sm margin-r-10"
                                                                            onClick={() => openModalWithId(emp.EmpId)}
                                                                        > ViewOS</button>
                                                                   </td>

                                                                </tr>
                                                            )
                                                            )
                                                        )
                                                            :
                                                            (
                                                                <tr>
                                                                    <td colSpan="5" className='text-center'>No records found</td>
                                                                </tr>
                                                            )}
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>)
                                }

                            </div>
                        </div>
                    </div>

                    {/* Pagination Section */}
                    {/*{*/}
                    {/*    myBookingsList.count > recordsPerPage && <div className="pagination_section gap-2">*/}
                    {/*        <div className="pagination_section_item pagination_info">*/}
                    {/*           <a class="btn btn_info_light">Showing {currentPage + 1} of {Math.round((myBookingsList.count / recordsPerPage))} pages</a> */}
                    {/*        </div>*/}
                    {/*        <div className="pagination_section_item pagination_numbers">*/}
                    {/*            <ul>*/}
                    {/*                <li>*/}
                    {/*                    <a*/}
                    {/*                        title="First page"*/}
                    {/*                        className={currentPage === 0 ? 'disabled_button' : ''}*/}
                    {/*                        onClick={() => fetchBooking(0)}*/}
                    {/*                    >*/}
                    {/*                        <i className="fa fa-angle-double-left"></i>*/}
                    {/*                    </a>*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <a*/}
                    {/*                        className={currentPage === 0 ? 'disabled_button' : ''}*/}
                    {/*                        title="Previous page"*/}
                    {/*                        onClick={() => fetchBooking(currentPage - 1)}*/}
                    {/*                    >*/}
                    {/*                        <i className="fa fa-angle-left"></i>*/}
                    {/*                    </a>*/}
                    {/*                </li>*/}
                    {/*                <li className="mr0">*/}
                    {/*                    <a title="Current page number" className="pageNumber pagination_active">{currentPage + 1}</a>*/}
                    {/*                </li>*/}
                    {/*                <li className="ml0">*/}
                    {/*                    <a title="Total number of pages" className="total_page_number pagination_active">{Math.round((myBookingsList.count / recordsPerPage))}</a>*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <a*/}
                    {/*                        className={currentPage === Math.round(myBookingsList.count / recordsPerPage) - 1 ? 'disabled_button' : ''}*/}
                    {/*                        title="Next page"*/}
                    {/*                        onClick={() => fetchBooking(currentPage + 1)}*/}
                    {/*                    >*/}
                    {/*                        <i className="fa fa-angle-right"></i>*/}
                    {/*                    </a>*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <a*/}
                    {/*                        title="Last page"*/}
                    {/*                        className={currentPage === Math.round(myBookingsList.count / recordsPerPage) - 1 ? 'disabled_button' : ''}*/}
                    {/*                        onClick={() => fetchBooking(Math.round((myBookingsList.count / recordsPerPage)) - 1)}*/}
                    {/*                    >*/}
                    {/*                        <i className="fa fa-angle-double-right"></i>*/}
                    {/*                    </a>*/}
                    {/*                </li>*/}
                    {/*            </ul>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}


                </div>

            </div>

            <Modal scrollable className="content-admin-main" size="lg" show={isModalOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Outstanding List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="sf-bs-data-table">
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered example-dt-table aon-booking-table" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th>Invoice No</th>                                       
                                        <th>Customer Name</th>
                                        <th>Due on</th>
                                        {/* <th>Yearly Target</th>*/}
                                        <th>Pending Amount </th>
                                        <th>Due (in days)</th>
                                        {/*<th>Employee Name</th>*/}
                                        {/*<th>Employee No</th>*/}
                                        <th>Ageing</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        osList?.length > 0 ? (
                                            osList?.map(emp => (
                                                <tr key={uuidv4()}>
                                                    <td className="align_middle">{emp.InvoiceNo}</td>                                                   
                                                    <td className="align_middle">{emp.CustomerName}</td>
                                                    <td className="align_middle">{new Date(emp.DueOn).toLocaleDateString()}</td>
                                                    <td className="align_middle">{emp.PendingAmount?.toFixed(2)}</td>
                                                    <td className="align_middle">{emp.OutstandingDays}</td>
                                                    <td className="align_middle">{emp.Ageing}</td>
                                                </tr>
                                            )
                                            )
                                        )
                                            :
                                            (
                                                <tr>
                                                    <td colSpan="5" className='text-center'>No records found</td>
                                                </tr>
                                            )}
                                </tbody>

                            </table>
                        </div>
                    </div>                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={closeModal}>
                        Close
                    </button>                    
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SalesTarget;