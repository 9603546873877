import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useJwt } from "react-jwt";

/*import AuthService from '../../services/utility/authenticationService';*/
import { userEmailKey } from '../../constants/app-constant';


function AppSidebar() {

    //const auth = AuthService.getAuthToken();
    const [userEmail, setUserEmail] = useState('');
    const [role, setRole] = useState(localStorage.getItem('role'));
    //const { decodedToken } = useJwt(auth.token);

    //useEffect(() => {
    //    if (decodedToken) {
    //        const email =
    //            decodedToken?.[userEmailKey];
    //        setUserEmail(email);
    //    }
    //}, [decodedToken]);

    return (
        <>
            <nav id="sidebar-admin-wraper">
                <div className="pro-my-account-wrap text-break" style={{ color: '#022279', fontWeight: 'bold' }}>
                    {userEmail?.toUpperCase()}
                </div>
                <div className="admin-nav">
                    <ul className="">
                        
                        <li>
                            {
                                role === 'Admin' ? <NavLink to="/"><img className="img-fluid" src={require('../../assets/images/icons/dashboard.png')} alt="report" />
                                    <span className="admin-nav-text">Import Invoice</span></NavLink>
                                    :null
                            }                            
                        </li>
                        <li>
                            {
                                role === 'Admin' ?
                                    <NavLink to="/SalesInvoiceList"><img className="img-fluid" src={require('../../assets/images/icons/dashboard.png')} alt="report" />
                                        <span className="admin-nav-text">Invoice List</span></NavLink> : null
                            }
                        </li>
                        <li>
                            {
                                role === 'Admin' ? <NavLink to="/SalesTarget"><img className="img-fluid" src={require('../../assets/images/icons/dashboard.png')} alt="report" />
                                    <span className="admin-nav-text">Sales Target</span></NavLink> : null
                            }                            
                        </li>
                        <li>
                            {
                                role === 'Admin' ?
                                    <NavLink to="/Employee/null">
                                        <img className="img-fluid" src={require('../../assets/images/icons/profile.png')} alt="profile" />
                                        <span className="admin-nav-text">Create Employee</span></NavLink> : null
                            }
                        </li>
                        <li>
                            {
                                role === 'Admin' ?
                                    <NavLink to="/EmployeesList">
                                        <img className="img-fluid" src={require('../../assets/images/icons/profile.png')} alt="profile" />
                                        <span className="admin-nav-text">Employee List</span></NavLink> : null
                            }
                        </li>
                        <li>
                            {
                                role === 'Admin' ?
                                    <NavLink to="/EmployeeTarget">
                                        <img className="img-fluid" src={require('../../assets/images/icons/profile.png')} alt="profile" />
                                        <span className="admin-nav-text">Employee Target</span></NavLink> : null
                            }                            
                        </li>
                        <li>
                            {
                                role === 'Admin' ?
                                    <NavLink to="/OutstandingUpload">
                                        <img className="img-fluid" src={require('../../assets/images/icons/profile.png')} alt="profile" />
                                        <span className="admin-nav-text">Outstanding Import</span></NavLink> : null
                            }
                        </li>
                        
                        {/*<li>*/}
                        {/*    <NavLink to="/experience-details">*/}
                        {/*        <img className="img-fluid" src={require('../../assets/images/icons/best-employee.png')} alt="experience" />*/}
                        {/*        <span className="admin-nav-text">Experience</span></NavLink>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <NavLink to="/certification-details">*/}
                        {/*        <img className="img-fluid" src={require('../../assets/images/icons/certification.png')} alt="certification" />*/}
                        {/*        <span className="admin-nav-text">Certifications</span></NavLink>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <NavLink to="/my-bookings">*/}
                        {/*        <img className="img-fluid" src={require('../../assets/images/icons/calender.png')} alt="calender" />*/}
                        {/*        <span className="admin-nav-text">My Bookings</span></NavLink>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <NavLink to="/consultant-timeslot">*/}
                        {/*        <img className="img-fluid" src={require('../../assets/images/icons/time.png')} alt="time" />*/}
                        {/*        <span className="admin-nav-text">Time Solt</span></NavLink>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <NavLink to="/membership-order">*/}
                        {/*        <img className="img-fluid" src={require('../../assets/images/icons/checklist.png')} alt="checklist" />*/}
                        {/*        <span className="admin-nav-text">Membership Order</span></NavLink>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <NavLink to="/consultant-payout">*/}
                        {/*        <img className="img-fluid" src={require('../../assets/images/icons/invoice.png')} alt="Payout" />*/}
                        {/*        <span className="admin-nav-text">Payout</span></NavLink>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <NavLink to="/booking-report">*/}
                        {/*        <img className="img-fluid" src={require('../../assets/images/icons/report.png')} alt="report" />*/}
                        {/*        <span className="admin-nav-text">Report</span></NavLink>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <NavLink to="/payment-setup">*/}
                        {/*        <img className="img-fluid" src={require('../../assets/images/icons/payment-setup.png')} alt="report" />*/}
                        {/*        <span className="admin-nav-text">Payment Setup</span></NavLink>*/}
                        {/*</li>*/}
                        {/* <li>*/}{/*
                        */}{/*    <NavLink to="/forgot-password"><i className="fa fa-calendar"></i><span*/}{/*
                        */}{/*        className="admin-nav-text">Forgot Password</span></NavLink>*/}{/*
                        */}{/*</li>*/}{/*
                        */}{/*<li>*/}{/*
                        */}{/*    <NavLink to="/reset-password"><i className="fa fa-cogs"></i><span*/}{/*
                        */}{/*        className="admin-nav-text">Reset Password</span></NavLink>*/}{/*
                        */}{/*</li> */}
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default AppSidebar;