import React, { useEffect, useState } from 'react';
import { getFinancialYear } from '../../services/api/salesTargetService';
import { saveEmployeeTarget, getEmployeeTarget } from '../../services/api/employeeTargetService';
import { v4 as uuidv4 } from 'uuid';


function EmployeeFYTarget() {
    const [empList, setEmpList] = useState([]);
    const [financialYear, setFinancialYear] = useState(0);
    const [financialYearList, setFinancialYearList] = useState([]);
    const [loading, isLoading] = useState(false);    
   

    const getFinancialYearList = async () => {
        try {
            isLoading(true);
            const response = await getFinancialYear();
            setFinancialYearList(response.data);
            console.log(financialYearList);
            isLoading(false);
        } catch (err) {
            isLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            getFinancialYearList();
        })();
    }, []);


    const getEmployeesTarget = async () => {
        console.log(financialYear);        
        if (financialYear != 0)
            try {
                isLoading(true);
                const response = await getEmployeeTarget(financialYear);
                console.log(response);
                setEmpList(response.data);
                isLoading(false);
            } catch (err) {
                isLoading(false);
            }
    }

    const SaveEmployeesTarget = async () => {
        console.log(financialYear);        
        if (financialYear != 0)
            try {
                isLoading(true);
                const obj = empList.map((emp, index) => ({
                    ...emp,  // Keep existing properties
                    FinancialYearId: financialYear,
                }));
              
                const response = await saveEmployeeTarget(obj);               
                isLoading(false);
            } catch (err) {
                isLoading(false);
            }
    }

    const handleAmcTargetChange = (id, value) => {
        const updateEmpList = empList.map(emp =>
            emp.EmpNo === id ? { ...emp, AmcTargetValue: value } : emp
        );
        setEmpList(updateEmpList);
    }

    const handleSparesTargetChange = (id, value) => {
        const updateEmpList = empList.map(emp =>
            emp.EmpNo === id ? { ...emp, SparesTargetValue : value } : emp
        );
        setEmpList(updateEmpList);
    }

    const handleServiceTargetChange = (id, value) => {
        const updateEmpList = empList.map(emp =>
            emp.EmpNo === id ? { ...emp, ServiceTargetValue: value } : emp
        );
        setEmpList(updateEmpList);
    }

    return (
        <>
            {/* My Booking Section */}
            <div className="card aon-card">
                <div className="card-header aon-card-header">
                    <h4>Sales Target</h4>
                </div>
                <div className="card-body aon-card-body">

                    <div className="sf-availability-times-tab m-b20">
                        <div className="sf-custom-tabs sf-custom-new">
                            <div className="sf-tabs-content">
                                {/* Filter Section */}
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label>Select Financial Year</label>
                                            <div className="aon-inputicon-box  datepicker-with-icon">
                                                <select className="form-control" name="Year"
                                                    onChange={(e) => setFinancialYear(e.target.value)}>
                                                    <option value="">Select a Financial Year</option>
                                                    {financialYearList && financialYearList.length > 0 && financialYearList?.map((item, key) => (
                                                        <option key={key} value={item.Id}>{item.Name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-1">
                                        <button type="button"
                                            className="admin-button m-t-38 assignButton btn margin-r-10"
                                            onClick={() => getEmployeesTarget()}>Get </button>
                                    </div>
                                    <div className="col-lg-2 ">
                                        <button type="button"
                                            className="admin-button m-t-38 assignButton btn margin-r-10"
                                            onClick={() => SaveEmployeesTarget()}>Save </button>
                                    </div>
                                </div>

                                {/* Loader */}
                                {
                                    loading && (
                                        <div className="skeleton p-0">
                                            {[...Array(5)].map(() => (
                                                <div key={uuidv4()} className="line mb-1" style={{ height: '67px', width: '100%' }}></div>
                                            ))}
                                        </div>
                                    )
                                }
                             
                                {/* MyBooking table section */}
                                {
                                    !loading && (
                                        <div className="sf-bs-data-table">
                                            <div className="table-responsive">
                                                <table className="table table-striped table-bordered example-dt-table aon-booking-table" style={{ width: '100%', tableLayout: 'fixed' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Employee No</th>
                                                            <th>Employee Name</th>
                                                            <th>Department</th>                                                            
                                                            <th>Spares Target</th>
                                                            <th>Services Target</th>
                                                            <th>Amc Target</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            empList?.length > 0  ? (
                                                                empList?.map(emp => (
                                                                    <tr key={uuidv4()}>
                                                                        <th>{emp.EmpNo}</th>
                                                                        <td>{emp.EmpName}</td>
                                                                        <td>{emp.Department}</td>                                                                        
                                                                        <td>
                                                                            <input type="text" className="form-control sf-form-control" type="number"
                                                                                value={emp.SparesTargetValue} name='empName' onChange={(e) => handleSparesTargetChange(emp.EmpNo, e.target.value)} />
                                                                        </td>
                                                                        <td>
                                                                            <input type="text" className="form-control sf-form-control" type="number"
                                                                                value={emp.ServiceTargetValue} name='empName' onChange={(e) => handleServiceTargetChange(emp.EmpNo, e.target.value)} />
                                                                        </td>
                                                                        <td>
                                                                            <input type="text" className="form-control sf-form-control" type="number"
                                                                                value={emp.AmcTargetValue} name='empName' onChange={(e) => handleAmcTargetChange(emp.EmpNo, e.target.value)} />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                )
                                                            )
                                                                :
                                                                (
                                                                    <tr>
                                                                        <td colSpan="5" className='text-center'>No records found</td>
                                                                    </tr>
                                                                )}
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>)
                                }

                            </div>
                        </div>
                    </div>


                    {/* Pagination Section */}
                    {/*{*/}
                    {/*    myBookingsList.count > recordsPerPage && <div className="pagination_section gap-2">*/}
                    {/*        <div className="pagination_section_item pagination_info">*/}
                    {/*           <a class="btn btn_info_light">Showing {currentPage + 1} of {Math.round((myBookingsList.count / recordsPerPage))} pages</a> */}
                    {/*        </div>*/}
                    {/*        <div className="pagination_section_item pagination_numbers">*/}
                    {/*            <ul>*/}
                    {/*                <li>*/}
                    {/*                    <a*/}
                    {/*                        title="First page"*/}
                    {/*                        className={currentPage === 0 ? 'disabled_button' : ''}*/}
                    {/*                        onClick={() => fetchBooking(0)}*/}
                    {/*                    >*/}
                    {/*                        <i className="fa fa-angle-double-left"></i>*/}
                    {/*                    </a>*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <a*/}
                    {/*                        className={currentPage === 0 ? 'disabled_button' : ''}*/}
                    {/*                        title="Previous page"*/}
                    {/*                        onClick={() => fetchBooking(currentPage - 1)}*/}
                    {/*                    >*/}
                    {/*                        <i className="fa fa-angle-left"></i>*/}
                    {/*                    </a>*/}
                    {/*                </li>*/}
                    {/*                <li className="mr0">*/}
                    {/*                    <a title="Current page number" className="pageNumber pagination_active">{currentPage + 1}</a>*/}
                    {/*                </li>*/}
                    {/*                <li className="ml0">*/}
                    {/*                    <a title="Total number of pages" className="total_page_number pagination_active">{Math.round((myBookingsList.count / recordsPerPage))}</a>*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <a*/}
                    {/*                        className={currentPage === Math.round(myBookingsList.count / recordsPerPage) - 1 ? 'disabled_button' : ''}*/}
                    {/*                        title="Next page"*/}
                    {/*                        onClick={() => fetchBooking(currentPage + 1)}*/}
                    {/*                    >*/}
                    {/*                        <i className="fa fa-angle-right"></i>*/}
                    {/*                    </a>*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <a*/}
                    {/*                        title="Last page"*/}
                    {/*                        className={currentPage === Math.round(myBookingsList.count / recordsPerPage) - 1 ? 'disabled_button' : ''}*/}
                    {/*                        onClick={() => fetchBooking(Math.round((myBookingsList.count / recordsPerPage)) - 1)}*/}
                    {/*                    >*/}
                    {/*                        <i className="fa fa-angle-double-right"></i>*/}
                    {/*                    </a>*/}
                    {/*                </li>*/}
                    {/*            </ul>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}


                </div>

            </div>

        </>
    )
}

export default EmployeeFYTarget;