import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import * as XLSX from "xlsx";
import { uploadSalesImport , getSalesInvoiceList } from '../../services/api/dashboardService';
import { v4 as uuidv4 } from 'uuid';
import AlertService from "../../services/CommonServices/alertService"

function DashboardContainer() {

  //const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [failedList, setFailedList] = useState([]);
    const [uploadedList, setUploadedList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [search, setSearch] = useState('');
    const [active, setActive] = useState('Upcoming');

    const UploadSalesImport = async()=> {
        try {
            setPageLoader(true);
            const response = await uploadSalesImport(data);          
            setFailedList(response.data.failedList);
            //setUploadedList(response.data.list);
            await AlertService.successAlert("Uploaded Successfully");
            setPageLoader(false);
        } catch (err) {
            setPageLoader(false);
        }
    };

    
    const handleFileUpload = (e) => {
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "binary", cellDates: true, });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = XLSX.utils.sheet_to_json(sheet);
            setData(parsedData);
            console.log(parsedData);
        };
    }
 
  const [pageLoader, setPageLoader] = useState(false);

  return (
    <>
      {
        pageLoader && (
          <div className="skeleton p-0">
            {[...Array(1)].map(() => (
              <div key={uuidv4()} className="line mb-1" style={{ height: '500px', width: '100%' }}></div>
            ))}
          </div>
        )
      }
                          {!pageLoader && (
                              <>
                                  <div className="card aon-card">
                                      <div className="card-header aon-card-header" hidden>
                                          <h4>
                                              Upload Import
                                          </h4>
                                      </div>
                                      <div className="card-body aon-card-body">

                                          <div className="row">
                                              <div className="col-lg-2">
                                                  <div className="form-group">
                                                      <label>File Upload</label>
                                                      <div className="aon-inputicon-box  datepicker-with-icon">
                                                          <input
                                                              type="file"
                                                              accept=".xlsx, .xls"
                                                              onChange={handleFileUpload}
                                                          />
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-lg-2">
                                                  <div className="form-group">
                                                      <label> </label>
                                                      {/*<div className="aon-inputicon-box datepicker-with-icon">*/}
                                                      {/*  */}{/*<DatePicker*/}
                                                      {/*  */}{/*  showIcon*/}
                                                      {/*  */}{/*  selected={filterDate.toDate}*/}
                                                      {/*  */}{/*  onChange={(date) => handleToDate(date)}*/}
                                                      {/*  */}{/*  placeholderText="To date"*/}
                                                      {/*  */}{/*  className="form-control sf-form-control"*/}
                                                      {/*  */}{/*/>*/}
                                                      {/*</div>*/}
                                                      {/*{validEndDate && (*/}
                                                      {/*  <p className="invalid_date">*/}
                                                      {/*    The end date should be greater than the start date.*/}
                                                      {/*  </p>*/}
                                                      {/*)}*/}
                                                  </div>
                                              </div>
                                              <div className="col-lg-2 ">
                                                  <button
                                                      type="button"
                                                      style={{ marginTop: '28px' }}
                                                      accept
                                                      className="admin-button m-t-38 assignButton btn margin-r-10"
                                                      onClick={() => UploadSalesImport()}
                                                  >
                                                      Upload
                                                  </button>
                                              </div>
                                          </div>

                                          {/*{dashboardData && dashboardData.chart && dashboardData.chart.length > 0 &&*/}
                                          {/*  <>*/}
                                          {/*    {dashboardChartData.options && dashboardChartData.series && (*/}
                                          {/*      <div className="p-3">*/}
                                          {/*        <Chart*/}
                                          {/*          options={dashboardChartData.options}*/}
                                          {/*          series={dashboardChartData.series}*/}
                                          {/*          type="bar"*/}
                                          {/*          height={350}*/}
                                          {/*        />*/}
                                          {/*      </div>*/}
                                          {/*    )}*/}
                                          {/*  </>*/}
                                          {/*}*/}
                                          {/*{dashboardData && dashboardData.chart && dashboardData.chart.length === 0 &&*/}
                                          {/*  <div className='text-center p-3 mb-5'> No Record</div>*/}
                                          {/*}*/}

                                      </div>
                                      {
                                          failedList && failedList.length > 0 && (<div className="sf-bs-data-table">
                                              <div className="table-responsive">
                                                  <h5>Failed List</h5>
                                                  <table className="table table-striped table-bordered example-dt-table aon-booking-table" style={{ width: '100%', tableLayout: 'fixed' }}>
                                                      <thead>
                                                          <tr>
                                                              <th>Employee Name</th>
                                                              <th>Yearly Target</th>
                                                              <th>Target Acheived</th>
                                                              <th>Difference</th>
                                                              <th>Action</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>

                                                          {

                                                              failedList?.map(emp => (
                                                                  <tr key={uuidv4()}>
                                                                      <td>{emp.InvoiceNo}</td>
                                                                      <td>{emp.InvoiceDate}</td>
                                                                      <td>{emp.CustomerName}</td>
                                                                      <td>{emp.SalesPerson}</td>
                                                                      <td>{emp.EmpId}</td>
                                                                      <td>{emp.Amount}</td>


                                                                  </tr>
                                                              )
                                                              )
                                                          }
                                                      </tbody>

                                                  </table>
                                              </div>
                                          </div>)
                                      }
                                  </div>
                              </>
                          )}
                    
    </>
  );
}

export default DashboardContainer;
