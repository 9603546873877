import React, { useEffect, useState } from "react";
import { uploadSalesImport, getSalesInvoiceList } from '../../services/api/dashboardService';
import { v4 as uuidv4 } from 'uuid';

function SalesInvoiceList() {

    //const dispatch = useDispatch();   
    const [uploadedList, setUploadedList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [search, setSearch] = useState('');
  

    const getInvoiceList = async () => {
        const response = await getSalesInvoiceList();
        setUploadedList(response.data);
        setFilteredList(response.data);
    };

    const handleInputChange = (e) => {
        setSearch(e.target.value);
    };

    const handleSearch = () => {

        console.log(uploadedList.filter(s => s.EmployeeName.toLowerCase().includes(search.toLowerCase()) ||
            s.InvoiceNo.toLowerCase().includes(search.toLowerCase()) || s.CustomerName.includes(search.toLowerCase())));

        setFilteredList(uploadedList.filter(s => s.EmployeeName.toLowerCase().includes(search.toLowerCase()) ||
            s.InvoiceNo.toLowerCase().includes(search.toLowerCase()) || s.CustomerName.includes(search.toLowerCase())));

        console.log(filteredList);
    };

    const clear = () => {
        setFilteredList(uploadedList);
        setSearch('');
    }

    useEffect(() => {
        (async () => {
            getInvoiceList();
        })();
    }, []);

   
    const [pageLoader, setPageLoader] = useState(false);

    return (
        <>
            {
                pageLoader && (
                    <div className="skeleton p-0">
                        {[...Array(1)].map(() => (
                            <div key={uuidv4()} className="line mb-1" style={{ height: '500px', width: '100%' }}></div>
                        ))}
                    </div>
                )
            }
            {!pageLoader && (
                <>
                    <div className="card aon-card">
                        <div className="card-header aon-card-header" hidden>
                            <h4>
                                Sales Invoice List
                            </h4>
                        </div>
                        <div className="card-body aon-card-body">
                            <div className="row">
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label>Search</label>
                                        <div className="aon-inputicon-box  datepicker-with-icon">
                                            <input className="form-control sf-form-control" type="text"
                                                value={search}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                               
                                <div className="col-lg-1">
                                    <button
                                        type="button"
                                       
                                        accept
                                        className="admin-button m-t-38 assignButton btn margin-r-10 "
                                        onClick={() => handleSearch()}
                                    >
                                        Search
                                    </button>
                                </div>
                                <div className="col-lg-1 ">
                                    <button
                                        type="button"
                                        style={{ marginLeft: 10+'px' }}
                                        className="admin-button m-t-38 assignButton btn margin-r-10"
                                        onClick={() => clear()}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>
                          
                         
                        
                        {
                            filteredList && filteredList.length > 0 && (<div className="sf-bs-data-table">
                                <div className="table-responsive">
                                    <h5> List</h5>
                                    <table className="table table-striped table-bordered example-dt-table aon-booking-table" style={{ width: '100%', tableLayout: 'fixed' }}>
                                        <thead>
                                            <tr>
                                                <th>Invoice No</th>
                                                <th>Invoice Date</th>
                                                <th>Invoice Type</th>
                                                <th>Customer Name</th>
                                                <th>Sales Person</th>
                                                <th>Employee Name</th>                                               
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {

                                                    filteredList?.map(emp => (
                                                    <tr key={uuidv4()}>
                                                        <td>{emp.InvoiceNo}</td>
                                                        <td>{new Date(emp.InvoiceDate).toLocaleDateString()}</td>
                                                        <td>{emp.InvoiceType}</td>
                                                        <td>{emp.CustomerName}</td>
                                                        <td>{emp.SalesPerson}</td>
                                                        <td>{emp.EmployeeName}</td>
                                                        <td>{emp.Amount}</td>
                                                    </tr>
                                                )
                                                )
                                            }
                                        </tbody>

                                    </table>
                                </div>
                            </div>)
                        }
                        </div>
                    </div>
                </>
            )}

        </>
    );
}

export default SalesInvoiceList;
