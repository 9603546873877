import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useJwt } from "react-jwt";
import { userEmailKey } from '../../constants/app-constant';

//import AuthService from '../../services/utility/authenticationService';

function AppHeader({ isLoginExist }) {

  //const auth = AuthService.getAuthToken();
  //let [showDrop, setShowDrop] = useState(false);
  //const [userEmail, setUserEmail] = useState('')
  //const { decodedToken } = useJwt(auth.token);
  //const navigate = useNavigate();


  //useEffect(() => {
  //  if (decodedToken) {
  //    const email =
  //      decodedToken?.[userEmailKey];
  //    setUserEmail(email);
  //  }
  //}, [decodedToken]);

  //const showLogoutDrop = () => {
  //  setShowDrop(showDrop = !showDrop)
  //}

  //const logOut = () => {
  //  AuthService.clearAuthDetail();
  //  setShowDrop(false);
  //  navigate('/login')
  //}

  //const changePassword = () => {
  //  setShowDrop(false);
  //  navigate('/change-password')
  //}

  return (
    <>
      <header id="header-admin-wrap" className="header-admin-fixed">

        <div id="header-admin">
          <div className="container">

            <div className="header-left">
              <div className="my-account-logo">
                <img className="img-fluid" src={require('../../assets/images/logo-dark.png')} alt="Logo" />
              </div>
            </div>

            {/*{isLoginExist && (*/}
            {/*  <div className="header-right">*/}
            {/*    <ul className="header-widget-wrap">*/}
            {/*      <li className={`header-widget ${showDrop && 'active'}`}>*/}
            {/*        <div className="aon-admin-messange sf-toogle-btn" onClick={() => showLogoutDrop(showDrop)}>*/}
            {/*          <span className="feather-user-pic">{userEmail.charAt(0).toUpperCase()}</span>*/}
            {/*        </div>*/}
            {/*        <div className="ws-toggle-popup popup-tabs-wrap-section user-welcome-area">*/}
            {/*          <ul className="user-welcome-list">*/}
            {/*            <li><strong>Welcome , <span className="site-text-primary text-break">{userEmail}</span></strong></li>*/}
            {/*            <li onClick={changePassword}><a><img className="img-fluid" src={require('../../assets/images/icons/password.png')} alt="certification" /> Change Password</a></li>*/}
            {/*            <li onClick={logOut}><a><i className="feather-log-out"></i> Log Out</a></li>*/}
            {/*          </ul>*/}
            {/*        </div>*/}
            {/*      </li>*/}
            {/*    </ul>*/}
            {/*  </div>*/}
            {/*)}*/}


          </div>
        </div>
      </header >
    </>
  );
}

export default AppHeader;