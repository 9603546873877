import axios from 'axios';
//import AuthService from './utility/authenticationService';

const adminInstance = axios.create({
    //baseURL: process.env.REACT_APP_ADMIN_BASE_URL,
    baseURL: 'https://localhost:44313/'
    //baseURL: 'https://demo.smartauto-solutions.in/',
});

adminInstance.interceptors.request.use(
    (config) => {
        const authTokenDetail = localStorage.getItem('loginToken');
       // console.log(authTokenDetail);
    //if (authTokenDetail) {
      config.headers['Authorization'] = `Bearer ${authTokenDetail}`;
    //}
    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

adminInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);


export { adminInstance };
