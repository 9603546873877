import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { getSalesTarget, getFinancialYear } from '../../services/api/salesTargetService';
import { saveEmployee, getEmployee} from '../../services/api/employeeService';
import { v4 as uuidv4 } from 'uuid';
import AlertService from "../../services/CommonServices/alertService";
//import { alertMessages } from '../../constants/app-constant';

function EmployeeForm() {

    const [isPageLoader, setPageLoader] = useState(false);   
    const [isButtonLoader, setButtonLoader] = useState(false);
    const [EmpNo, setEmpNo] = useState('');
    const [EmpId, setEmpId] = useState(0);
    const [EmpName, setEmpName] = useState('');
    const [Department, setDepartment] = useState('');
    const [UserName, setUserName] = useState('');
    
    const { empId } = useParams();

    const getEmployeeDetails = async () => {
        try {
            const response = await getEmployee(empId);
            setEmpNo(response.data.EmpNo);
            setEmpName(response.data.EmpName);
            setDepartment(response.data.Department);
            setUserName(response.data.UserName);
        } catch (err) {

        }
    };

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "all",
    });

    useEffect(() => {
        (async () => {
            if(empId != null)
            setPageLoader(true);
            await getEmployeeDetails();            
            setPageLoader(false);
        })();
    }, []);
   
    const onSubmit = async () => {
        setButtonLoader(true);
        const empObj = {
            'EmpId': EmpId,
            'EmpNo': EmpNo,
            'EmpName': EmpName,
            'Department': Department,
            'UserName': UserName,
        };
        try {
            setButtonLoader(true);
            const response = await saveEmployee(empObj);
            await AlertService.successAlert('Saved Successfully');
            setButtonLoader(false);
            setEmpNo('');
            setEmpName('');
            setDepartment('');
        } catch (err) {
            setButtonLoader(false);
        }

    }

    const onError = () => {
        console.log("on submit error")
    }

   
    const renderSkeleton = () => (
        <div className="skeleton p-4" key={uuidv4()}>
            <div className="row">
                {[...Array(7)].map(() => (
                    <>
                        <div className="col-lg-6 mb-4" key={uuidv4()}>
                            <div className="line mb-2" style={{ height: '20px', width: '40%' }}> </div>
                            <div className="line" style={{ height: '50px', width: '100%' }}> </div>
                        </div>
                    </>
                ))}
            </div>
        </div>
    );

    return (
        <>
            {isPageLoader && [...Array(2)].map(renderSkeleton)}

            {!isPageLoader  &&
            <>
               
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div>
                        <div className="card aon-card">
                            <div className="card-header aon-card-header"><h4><i className="fa fa-user"></i> Employee</h4></div>
                            <div className="card-body aon-card-body">
                                <form>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div>
                                                <input className="form-control sf-form-control" name="empId" type="hidden" value={EmpId}
                                                    />
                                            </div>
                                            <div className="form-group">
                                                <label>Employee No</label>
                                                <div>
                                                    <input className="form-control sf-form-control" name="empNo" type="text" value={EmpNo} onChange={(e) => setEmpNo(e.target.value)}
                                                         />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Employee Name</label>
                                                <div>
                                                    <input className="form-control sf-form-control" name="empName" type="text" value={EmpName}
                                                        onChange={(e)=> setEmpName(e.target.value)} />
                                                </div>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Department</label>
                                                <div>
                                                    <input className="form-control sf-form-control" name="designation" type="text" value={Department}
                                                        onChange={(e) => setDepartment(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>UserName</label>
                                                <div>
                                                    <input className="form-control sf-form-control" name="designation" type="text" value={UserName}
                                                        onChange={(e) => setUserName(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                       
                                      
                                    </div>
                                </form>

                            </div>
                        </div>

                       
                        <div className="col-md-12 p-0">
                            {/* <button className="admin-button float-right" type="submit">
                                    Save Profile
                                    </button> */}
                            <button type="submit" className="admin-button float-right wid-200">
                                {isButtonLoader && <i className="fa fa-spinner fa-spin me-2"></i>} Save Profile
                            </button>
                        </div>

                    </div>
                </form>
            </>                
            }
        </>
    )
}

export default EmployeeForm;