import React from 'react';

import { useState } from 'react';
import { useForm } from "react-hook-form";
import { apiStatus, alertMessages } from '../../constants/app-constant';
import { ForgotPassword } from '../../services/api/loginService';

function AppForgotPassword() {

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "all",
    });

    const [userName, setUserName] = useState("");
    const [response, setResponse] = useState("");
    const onSubmit = async(formvalues) => {
        const obj = { Email: userName };
        const response = await ForgotPassword(obj);        
        setResponse(response.data);       
    };

    const onError = (formvalues) => {
        //  console.log(formvalues)
    };

    return (
        <>
            <div className="d-flex justify-content-center align-items-center login_section" style={{ height: '100vh' }}>
                <div className="card aon-card sf-contact-info-box" style={{ flex: '0 1 500px' }}>
                    <div className="card-header aon-card-header">
                        <h4><i className="fa fa-user"></i> Forgot Password</h4>
                    </div>
                    <div className="card-body aon-card-body">
                        <form className="aon-login-form" onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className="row">

                                <div className="col-md-12">
                                    <div className={`form-group ${errors.userName ? 'error_field' : ''}`}>
                                        <div className="aon-inputicon-box">
                                            <input className="form-control sf-form-control"
                                                id="userName" name="userName" type="text" placeholder="User Name"
                                                onBlur={(e) => setUserName(e.target.value)}
                                            />
                                            <i className="aon-input-icon fa fa-user"></i>
                                        </div>
                                        {/*{*/}
                                        {/*    errors.userName &&*/}
                                        {/*    <ErrorMessage errorType={errors.userName.type} errorFieldName={'User Name'} />*/}
                                        {/*}*/}
                                    </div>
                                </div>
                                             {/* TO Do Cange it to Status Code */}               
                                {
                                    response && response === 'Invalid Username' && (
                                        <div className="col-md-12" >
                                            <div className="bg-danger text-white p-2 mb-4 rounded">
                                                {response}
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    response && response === 'Mail sent successfully' && (
                                        <div className="col-md-12" >
                                            <div className="bg-success text-white p-2 mb-4 rounded">
                                                {response}
                                            </div>
                                        </div>
                                    )
                                }

                                <div className="col-md-12">
                                    <button type="submit" className="site-button w-100">Submit
                                        <i className='feather-arrow-right'></i></button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </>
    );
}

export default AppForgotPassword;