import { adminInstance } from '../axiosInstance';
import { apiAssets } from '../../constants/app-constant';

export async function saveEmployeeTarget(object) {
    return await adminInstance.post(apiAssets.employeeTarget, object);
}

export async function getEmployeeTarget(id) {
    return await adminInstance.get(apiAssets.getEmployeeTarget+ "/"+ id);
}
