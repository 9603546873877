import React from 'react';
import { createHashRouter } from "react-router-dom";
import AppLayout from '../pages/layout/AppLayout';
import Dashboard from '../pages/dashboard/Dashboard';
import SalesTarget from '../pages/Employee/SalesTarget';
import EmployeeMonthlyTarget from '../pages/Employee/EmployeeMonthlyTarget';
import EmployeeForm from '../pages/Employee/EmployeeForm';
import EmployeeFYTarget from "../pages/Employee/EmployeeFYTarget";
import EmployeeList from "../pages/Employee/EmployeeList";
import AppLogin from '../Components/login/AppLogin';
import OutstandingImport from '../pages/Outstanding/OutstandingImport';
import SalesInvoiceList from '../pages/dashboard/SalesInvoiceList';
import AppForgotPassword from '../Components/login/AppForgotPassword';


export default createHashRouter([
    {
        path: "/",
        element: <AppLayout />,
        children: [
            {
                path: "/",
                element: <Dashboard />,
            },
            {
                path: "EmployeeSalesTarget/:financialYear/:empId",
                element: <EmployeeMonthlyTarget/>,
            },
            {
                path: "SalesTarget",
                element: <SalesTarget />,
            },
            {
                path: "Employee/:empId",
                element: <EmployeeForm />,
            },
            {
                path: "EmployeeTarget",
                element: <EmployeeFYTarget/>
            },
            {
                path: "EmployeesList",
                element: <EmployeeList />
            },
            {
                path: "OutstandingUpload",
                element: <OutstandingImport />
            },
            {
                path: "SalesInvoiceList",
                element: <SalesInvoiceList />
            },
            {
                path: "SalesInvoiceList",
                element: <SalesInvoiceList />
            }            
        ],
    },
    {
        path: "login",
        element: <AppLogin />,
    }    ,
    {
        path: "ForgotPassword",
        element: <AppForgotPassword />
    }
   
]);