import { adminInstance } from '../axiosInstance';
import { apiAssets } from '../../constants/app-constant';

export async function saveEmployee(object) {
    return await adminInstance.post(apiAssets.saveEmployee, object);
}

export async function getEmployee(id) {
    return await adminInstance.get(apiAssets.employeeDetails + "/" + id);
}

export async function getEmployeeList(object) {
    return await adminInstance.get(apiAssets.employeeList);
}
