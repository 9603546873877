import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/styles/style.css';
import App from './App';

//import store from "./store/storeConfig";

const root = ReactDOM.createRoot(
  document.getElementById('root')
);

root.render(
      <App />    
);