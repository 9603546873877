import { adminInstance } from '../axiosInstance';
import { apiAssets } from '../../constants/app-constant';

export async function login(object) {
    return await adminInstance.post(apiAssets.login, object);
}

export async function ForgotPassword(object) {
    return await adminInstance.post(apiAssets.forgotPassword, object);
}

export async function ChangePassword(object) {
    return await adminInstance.post(apiAssets.changePassword, object);
}
