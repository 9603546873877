import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { uploadOutstanding } from '../../services/api/outstandingService';
import { v4 as uuidv4 } from 'uuid';
import AlertService from "../../services/CommonServices/alertService";

function OutstandingImport() {

    //const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [emplist, setempList] = useState([]);

    const UploadOutstandingImport = async () => {
        try {
            setPageLoader(true);
            const response = await uploadOutstanding(data);
            await AlertService.successAlert("Uploaded Successfully");
            console.log(response);
            setempList(response.data);
            setPageLoader(false);
        } catch (err) {
            setPageLoader(false);
        }
    };

    const handleFileUpload = (e) => {
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "binary", cellDates: true, });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = XLSX.utils.sheet_to_json(sheet);
            setData(parsedData);
            console.log(parsedData);
        };
    }

    const [pageLoader, setPageLoader] = useState(false);

    return (
        <>
            {
                pageLoader && (
                    <div className="skeleton p-0">
                        {[...Array(1)].map(() => (
                            <div key={uuidv4()} className="line mb-1" style={{ height: '500px', width: '100%' }}></div>
                        ))}
                    </div>
                )
            }

            {!pageLoader && (
                <>
                    <div className="card aon-card">
                        <div className="card-header aon-card-header" hidden>
                            <h4>
                                Outstanding Upload
                            </h4>
                        </div>
                        <div className="card-body aon-card-body">

                            <div className="row">
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label>File Upload</label>
                                        <div className="aon-inputicon-box  datepicker-with-icon">
                                            <input
                                                type="file"
                                                accept=".xlsx, .xls"
                                                onChange={handleFileUpload}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label> </label>
                                        {/*<div className="aon-inputicon-box datepicker-with-icon">*/}
                                        {/*  */}{/*<DatePicker*/}
                                        {/*  */}{/*  showIcon*/}
                                        {/*  */}{/*  selected={filterDate.toDate}*/}
                                        {/*  */}{/*  onChange={(date) => handleToDate(date)}*/}
                                        {/*  */}{/*  placeholderText="To date"*/}
                                        {/*  */}{/*  className="form-control sf-form-control"*/}
                                        {/*  */}{/*/>*/}
                                        {/*</div>*/}
                                        {/*{validEndDate && (*/}
                                        {/*  <p className="invalid_date">*/}
                                        {/*    The end date should be greater than the start date.*/}
                                        {/*  </p>*/}
                                        {/*)}*/}
                                    </div>
                                </div>
                                <div className="col-lg-2 ">
                                    <button
                                        type="button"
                                        style={{ marginTop: '28px' }}
                                        accept
                                        className="admin-button m-t-38 assignButton btn margin-r-10"
                                        onClick={() => UploadOutstandingImport()}
                                    >
                                        Upload
                                    </button>
                                </div>
                            </div>
                            {
                                !pageLoader && emplist?.length > 0 && (<div className="sf-bs-data-table">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-bordered example-dt-table aon-booking-table" style={{ width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th>Invoice No</th>
                                                    <th>Invoice Type</th>
                                                    <th>Customer Name</th>
                                                    <th>Due on</th>
                                                    {/* <th>Yearly Target</th>*/}
                                                        <th>Pending Amount </th>
                                                        <th>Due (in days)</th>
                                                        <th>Employee Name</th>
                                                        <th>Employee No</th>
                                                    <th>Ageing</th>
                                                   
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    emplist?.length > 0 ? (
                                                        emplist?.map(emp => (
                                                            <tr key={uuidv4()}>
                                                                <td className="align_middle">{emp.InvoiceNo}</td>
                                                                <td className="align_middle">{emp.InvoiceType}</td>
                                                                <td className="align_middle">{emp.CustomerName}</td>
                                                                <td className="align_middle">{emp.DueOn}</td>
                                                                <td className="align_middle">{emp.PendingAmount?.toFixed(2)}</td>
                                                                <td className="align_middle">{emp.OutstandingDays}</td>
                                                                <td className="align_middle">{emp.SalesPersonName}</td>                                                             
                                                                
                                                                <td className="align_middle">{emp.EmpNo}</td>
                                                                <td className="align_middle">{emp.Ageing}</td>
                                                              
                                                            </tr>
                                                        )
                                                        )
                                                    )
                                                        :
                                                        (
                                                            <tr>
                                                                <td colSpan="9" className='text-center'>No records found</td>
                                                            </tr>
                                                        )}
                                            </tbody>

                                        </table>
                                    </div>
                                </div>)
                            }
                          
                        </div>
                       
                    </div>
                </>
            )}
        </>
    );
}

export default OutstandingImport;
