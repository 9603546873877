import { adminInstance } from '../axiosInstance';
import { apiAssets } from '../../constants/app-constant';

export async function getSalesTarget(id) {
    return await adminInstance.post(apiAssets.salesYearlyTarget, id);
}

export async function getFinancialYear() {
    console.log(apiAssets.financialYearList);
    return await adminInstance.get(apiAssets.financialYearList);
}