import Swal from "sweetalert2";

const AlertService = {

    async successAlert(text) {
        return Swal.fire({
            title: 'Great job!',
            text,
            icon: 'success',
        });
    },

    warningAlert(text) {
        return Swal.fire({
            title: 'Attention!',
            text,
            icon: 'warning',
        });
    },

    errorAlert(text) {
        return Swal.fire({
            title: 'Oops..!',
            text,
            icon: 'error',
        });
    },

    yesNoWarningAlert(text) {
        return Swal.fire({
            title: 'Attention!',
            text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: `No`,
        })
    },


}


export default AlertService;
