import React, { useEffect, useState } from 'react';
import { getEmployeeList} from '../../services/api/employeeService';
import { v4 as uuidv4 } from 'uuid';

function EmployeeList() {
    
    const [empList, setEmpList] = useState([]);
    const [loading, isLoading] = useState(false);

    const getEmployeesList = async () => {
        try {
            isLoading(true);
            const response = await getEmployeeList();
            setEmpList(response.data);
            isLoading(false);
        } catch (err) {
            isLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            getEmployeesList();
        })();
    }, []);

    const handleClick = (empId) => {
        window.location.href = "#/Employee/" + empId;
    }

    return (
        <>
            {/* My Booking Section */}
            <div className="card aon-card">
                <div className="card-header aon-card-header">
                    <h4>Employee List</h4>
                </div>
                <div className="card-body aon-card-body">
                    <div className="sf-availability-times-tab m-b20">
                        <div className="sf-custom-tabs sf-custom-new">
                            <div className="sf-tabs-content">
                                {/* Filter Section */}
                              
                                {/* MyBooking table section */}
                                {
                                    !loading && (
                                        <div className="sf-bs-data-table">
                                            <div className="table-responsive">
                                                <table className="table table-striped table-bordered example-dt-table aon-booking-table" style={{ width: '100%', tableLayout: 'fixed' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Employee No</th>
                                                            <th>Employee Name</th>
                                                            <th>Department</th>                                                          
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            empList.length > 0 ? (
                                                                empList?.map(emp => (
                                                                    <tr key={uuidv4()}>
                                                                        <th>{emp.EmpNo}</th>
                                                                        <td>{emp.EmpName}</td>
                                                                        <td>{emp.Department}</td>
                                                                        <td className="align_middle">
                                                                            <button type="button"
                                                                                className="btn btn-info btn-sm margin-r-10"
                                                                                onClick={() => handleClick(emp.EmpId)}
                                                                            > View</button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                )
                                                            )
                                                                :
                                                                (
                                                                    <tr>
                                                                        <td colSpan="5" className='text-center'>No records found</td>
                                                                    </tr>
                                                                )}
                                                    </tbody>

                                                </table>
                                            </div>
                                            </div>)
                                }

                                        </div>
                        </div>
                        </div>


                    </div>

                </div>

        </>
            )
}

export default EmployeeList;