import React from 'react';
import {
    RouterProvider,HashRouter} from "react-router-dom";
import Router from './routes/router'

function App() {
  return (
      <div className="App">
        <RouterProvider router={Router} />
    </div>
  );
}

export default App;
