

export const apiAssets = {
    salesImportUpload: "/api/InvoiceImport",
    salesYearlyTarget: "api/SalesTarget",
    employeeList: "/GetEmployees",
    saveEmployee:"api/Employee",
    employeeDetails: "/GetEmployeeDetails",
    financialYearList: "/GetFinancialYear",
    employeeTarget: "api/EmployeeTarget",
    getEmployeeTarget: "/GetEmployeeTarget",
    login: 'api/Account/Login',
    forgotPassword: 'api/Account/ForgotPassword',
    changePassword: 'api/Account/ChangePassword',
    outstandingImportUpload: "/api/OutStandingImport",
    outstandingList: "/GetEmployeeOutstanding",
    salesInvoiceList:"api/InvoiceImport"
}

export const loginStaorageKey = 'loginToken';

export const userEmailKey = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress";